<template>
<div class="sidebar">
  <ul class="root-menu">
    <submenu v-for="(item, index) in menus" :key="index"
      :item="item"
      :badges="sidebarBadge"
      :routeName="$route.name"
      :isMin="$root.$data.isToggle"
      :level="1"
      indexName="name"
      currentClass="has-current"
      titleClass="sidebar-link"
      :indent="10">
    </submenu>
  </ul>
  <!-- sidebar 插件位置 -->
 <!--  <div class="sidebar-toggler text-center" ref="sidebarToggler" @click="changeToggle()">
    <i class="fa fa-lg fa-angle-left"></i>
  </div> -->
</div>
</template>

<script>
// import router from '@/router';
// import FlowSet from '@/views/flow/FlowSet';
import Submenu from '../utils/Submenu';

export default {
  name: 'main-sidebar',
  data() {
    return {
      menuList: false,
      // menus: [
      //   {
      //     title: '系统设置',
      //     icon: 'gears',
      //     can: true,
      //     children: [
      //       { itle: '角色管理', icon: 'user-circle-o', can: true, name: 'Role' },
      //       { itle: '权限管理', icon: '', can: true, name: 'Role' },
      //       { itle: '设置', icon: '', can: true, name: 'Role' },
      //     ],
      //   },
      // ],
      sidebarBadge: {},
    };
  },
  beforeCreate() {
    // const route = [
    //   {
    //     path: 'flowset',
    //     name: 'FlowSet',
    //     component: () => import('@/views/flow/FlowSet'),
    //   },
    // ];
    // const orgRoutes = router.options.routes;
    // const layout = orgRoutes.find(item => item.name === 'Layout');
    // layout.children.splice(1, 0, ...route);
    // // vue-router 3.10以后有addRoute('Layout', route[0]) 来添加子路由
    // router.addRoutes(orgRoutes);
  },
  components: {
    Submenu,
  },
  watch: {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    menus() {
      // let result = [
      //   { title: '人员管理', icon: 'users', name: 'Staff', can: true },
      // ];
      const result = [
        // { title: '首页', icon: 'home', can: true, perms: 'admin', name: 'User' },
        {
          title: '系统设置',
          icon: 'gears',
          can: true,
          children: [
            { title: '用户管理', icon: 'user-circle-o', can: true, perms: 'admin|user_manager', name: 'User' },
            { title: 'erp用户', icon: 'user-o', can: true, perms: 'admin', name: 'ErpUser' },
            { title: '功能管理', icon: 'cube', can: true, perms: 'admin', name: 'Promise' },
            { title: '角色管理', icon: 'users', can: true, perms: 'admin', name: 'Role' },
          ],
        },
        {
          title: '面料资源',
          icon: 'gears',
          can: true,
          children: [
            { title: '样卡登录', icon: '', can: true, perms: 'admin|ghyk', name: 'GhykCode' },
          ],
        },
        {
          title: '审批流程管理',
          icon: 'random',
          can: true,
          children: [
            { title: '流程模块', icon: '', can: true, perms: 'admin', name: 'FlowSet' },
            { title: '模块审批流程', icon: '', can: true, perms: 'admin', name: 'FlowCourse' },
          ],
        },
        { title: '发货凭证', icon: 'certificate', can: true, perms: 'admin|dv_certificate', name: 'DvBill' },
        {
          title: '库存物流',
          icon: 'truck',
          can: true,
          children: [
            { title: '供应商', icon: 'facebook', can: true, perms: 'admin|delivery_project_manager', name: 'Party' },
            { title: '发货管理', icon: 'bars', can: true, perms: 'admin|delivery_project_manager|dv_party', name: 'DvOrder' },
            { title: '发货记录', icon: 'exchange', can: true, perms: 'admin|dv_party', name: 'DvDelivery' },
            { title: '库存', icon: 'store', can: true, perms: 'admin|delivery_project_manager', name: 'DvStock' },
            { title: '采购入库', icon: 'store', can: true, perms: 'admin|delivery_project_manager', name: 'PoStock' },
            { title: '库存调拨', icon: 'store', can: true, perms: 'admin|delivery_project_manager', name: 'StockTransfer' },
            { title: '加工入库', icon: 'store', can: true, perms: 'admin|delivery_project_manager', name: 'DvProduct' },
            { title: '出库管理', icon: 'store', can: true, perms: 'admin|delivery_project_manager', name: 'StockOut' },
            { title: '开票通知', icon: '', can: true, perms: 'admin|delivery_project_manager|bill_check', name: 'Receipt' },
          ],
        },
        // {
        //   title: '样品图片',
        //   icon: 'database',
        //   can: true,
        //   children: [
        //     { title: '样品图片', icon: 'vcard', can: true, escPerms: 'dv_party', name: 'DImg' },
        //   ],
        // },
        {
          title: '基础数据',
          icon: 'database',
          can: true,
          children: [
            // { title: '快递查询', icon: '', can: true, perms: 'admin|user_manager', name: 'PackageReceive' },
            { title: '企业管理', icon: 'vcard', can: true, perms: 'admin', name: 'Company' },
            { title: '费用类别', icon: 'credit-card', can: true, perms: 'expense_type_manager', name: 'ExpenseType' },
            { title: '部门管理', icon: 'cubes', can: true, perms: 'admin', name: 'Dept' },
            // { title: '项目管理', icon: 'vcard', can: true, name: 'Project' },
            // { title: '进度管理', icon: 'vcard', can: true, name: 'RecordFill' },
            { title: '物流公司', icon: 'truck', can: true, perms: 'supplier', name: 'Supplier' },
          ],
        },
        {
          title: '打样流程',
          icon: 'database',
          can: true,
          children: [
            { title: '打样分工', icon: 'vcard', can: true, perms: 'dy_use|dy_step', name: 'DyStep' },
            { title: '打样管理', icon: 'vcard', can: true, perms: 'dy_use|dv_certificate', name: 'DySample' },
            { title: '打样', icon: 'vcard', can: true, perms: 'dy_sf', name: 'SfDySample' },
            { title: '打样总览', icon: 'vcard', can: true, perms: 'dy_summary_view', name: 'DySummary' },
          ],
        },
        {
          title: '工厂评分',
          icon: 'font',
          can: true,
          children: [
            { title: '工厂管理', icon: '', can: true, perms: 'admin|report', name: 'Factory' },
            { title: '评分项目', icon: '', can: true, perms: 'admin|report', name: 'ReportItem' },
            { title: '评分记录', icon: '', can: true, perms: 'admin|report', name: 'Report' },
          ],
        },
        {
          title: '资产管理',
          icon: 'font',
          can: true,
          children: [
            { title: '资产管理', icon: 'arrows', can: true, perms: 'admin|asset_manager|asset_input', name: 'Asset' },
          ],
        },
        {
          title: '缅甸管理',
          icon: 'balance-scale',
          can: true,
          children: [
            { title: '开票', icon: 'book', can: true, perms: 'mmr_expense_input|mmr_verify', name: 'Invoice' },
            { title: '支出', icon: 'dedent', can: true, perms: 'mmr_expense_input|mmr_verify', name: 'Expense' },
            { title: '收款', icon: 'indent', can: true, perms: 'mmr_revenue_input|mmr_verify', name: 'Revenue' },
            { title: '公司管理', icon: 'copyright', can: true, perms: 'mmr_verify', name: 'MmrCompany' },
            { title: '部门管理', icon: 'cubes', can: true, perms: 'mmr_expense_input', name: 'MmrDept' },
          ],
        },
        {
          title: 'Factory',
          icon: 'facebook',
          can: true,
          children: [
            { title: 'Factory Manager', icon: 'facebook', can: true, perms: 'own_factory_manager', name: 'OwnFactory' },
            { title: 'Stock Manager', icon: 'cubes', can: true, perms: 'own_factory_stock|own_factory_manager', name: 'OwfStock' },
            { title: 'Stock Logs', icon: 'dedent', can: true, perms: 'own_factory_stock|own_factory_manager', name: 'OwfLog' },
            { title: '库存', icon: 'cubes', can: true, perms: 'own_factory_stock|own_factory_manager', name: 'FactoryInventory' },
            { title: '出入库', icon: 'cubes', can: true, perms: 'own_factory_stock|own_factory_manager', name: 'InoutRecord' },
          ],
        },
        // {
        //   title: '货运管理',
        //   icon: 'truck',
        //   can: true,
        //   children: [
        //     { title: '工厂', icon: 'facebook', can: true, perms: 'delivery_order_manager', name: 'DelyCompany' },
        //     { title: '项目管理', icon: 'bars', can: true, perms: 'delivery_project_manager|wb_view', name: 'DelyProject' },
        //     { title: '订单管理', icon: 'bars', can: true, perms: 'delivery_order_manager|delivery_input|wb_view', name: 'DelyOrder' },
        //     {
        //       title: '发货管理',
        //       icon: 'exchange',
        //       can: true,
        //       perms: 'delivery_order_manager|delivery_input|wb_view',
        //       name: 'DelyRecord',
        //     },
        //   ],
        // },
        {
          title: '领用管理',
          icon: 'sign-out',
          can: true,
          children: [
            { title: '领用记录', icon: 'pencil-square-o', can: true, perms: 'use_record_manager|use_record_view', name: 'DelyUseRecord' },
          ],
        },
        {
          title: '统计',
          icon: 'bar-chart',
          can: true,
          children: [
            // { title: '订单录入', icon: 'dd', can: true, perms: 'order_statistic_manager', name: 'OrderStatisticManager' },
            { title: '用户管理', icon: 'users', can: true, perms: 'temp', name: 'Temp' },
            { title: '订单数据', icon: 'dd', can: true, perms: 'statistic_order|statistic', name: 'OrderStatistic' },
          ],
        },
        {
          title: '派车',
          icon: 'car',
          can: true,
          children: [
            { title: '未派车', icon: 'cars', can: true, perms: 'pcxt_manager', name: 'ToDispatch' },
            { title: '已派车记录', icon: 'cars', can: true, perms: 'pcxt_manager', name: 'Dispatch' },
            { title: '里程查询', icon: 'cars', can: true, perms: 'pcxt_manager', name: 'DispatchCost' },
            { title: '里程统计', icon: 'cars', can: true, perms: 'pcxt_manager', name: 'DispatchTj' },
          ],
        },
        {
          title: '物料管理',
          icon: 'bar-chart',
          can: true,
          children: [
            { title: '物料管理', icon: 'store', can: true, perms: 'ewuliao_manager', name: 'Wuliao' },
            { title: '出入库记录', icon: 'store', can: true, perms: 'ewuliao_manager', name: 'WuliaoLog' },
          ],
        },
        {
          title: '服装',
          icon: 'bar-chart',
          can: true,
          children: [
            // { title: '订单录入', icon: 'dd', can: true, perms: 'order_statistic_manager', name: 'OrderStatisticManager' },
            { title: '旧SKU管理', icon: 'store', can: true, perms: 'clothes', name: 'Clothes' },
            { title: '旧款库存详情', icon: 'store', can: true, perms: 'clothes_in_out', name: 'ClothesStore' },
            { title: 'SKU管理', icon: 'store', can: true, perms: 'clothes', name: 'EClothes' },
            { title: '0库存报警', icon: 'store', can: true, perms: 'clothes', name: 'EClothesWarn' },
            { title: '库存统计', icon: 'store', can: true, perms: 'clothes|clothes_stock_show', name: 'EStock' },
            { title: '入库', icon: 'store', can: true, perms: 'clothes|clothes_in_out', name: 'EClothesIn' },
            { title: '出库', icon: 'store', can: true, perms: 'clothes|clothes_in_out', name: 'EClothesOut' },
            { title: '出入库统计', icon: 'store', can: true, perms: 'clothes|clothes_stock_show', name: 'EClothesLog' },
            { title: '退货返库', icon: 'store', can: true, perms: 'clothes|clothes_in_out', name: 'EClothesBack' },
            { title: '退货审核', icon: '', can: true, perms: 'clothes|clothes_cw', name: 'EClothesCheck' },
            { title: '工厂入库查询', icon: '', can: true, perms: 'clothes|clothes_cw', name: 'SupplierLog' },
            { title: '出货统计', icon: '', can: true, perms: 'clothes|clothes_cw', name: 'OutTypes' },
            // { title: '老款审核记录', icon: '', can: true, perms: 'clothes|clothes_cw', name: 'ClothesReturn' },
            // { title: '入库', icon: 'store', can: true, perms: 'clothes_in_out', name: 'ClothesIn' },
            // { title: '出库', icon: 'store', can: true, perms: 'clothes_in_out', name: 'ClothesOut' },
            // { title: '出货单出库', icon: 'store', can: true, perms: 'clothes_in_out', name: 'BillOut' },
            // { title: '统计', icon: 'store', can: true, perms: 'clothes', name: 'ClothesLog' },
          ],
        },
        {
          title: '基础数据',
          icon: 'line-chart',
          can: true,
          children: [
            { title: '颜色编码', icon: 'circle', can: true, perms: 'clothes', name: 'EColor' },
            { title: '加工工厂', icon: 'circle', can: true, perms: 'clothes', name: 'ClothesSupplier' },
            { title: '分销商', icon: 'circle', can: true, perms: 'clothes', name: 'Distributor' },
            { title: '其他选项', icon: 'circle', can: true, perms: 'clothes', name: 'Dict' },
          ],
        },
        {
          title: '样卡管理',
          icon: 'photo',
          can: true,
          children: [
            { title: '样卡管理', icon: '', can: true, perms: 'sf_ykgl', name: 'YkSample' },
            { title: '出借', icon: '', can: true, perms: 'sf_ykgl', name: 'YkLend' },
            { title: '归还', icon: '', can: true, perms: 'sf_ykgl', name: 'YkReturn' },
            { title: '扫码记录', icon: '', can: true, perms: 'sf_ykgl', name: 'YkList' },
          ],
        },
      ];
      this.menuProcess(result, result[0]);
      // if (this.user.allPerms.indexof())
      // if (this.user.type === '4') {
      //   result = [
      //     {
      //       title: '基础数据',
      //       icon: 'gears',
      //       can: true,
      //       children: [
      //         { title: '项目管理', icon: 'vcard', can: true, name: 'Project' },
      //         { title: '进度管理', icon: 'vcard', can: true, name: 'RecordFill' },
      //       ],
      //     },
      //   ];
      // }
      // if (this.user.type === '3') {
      //   result = [
      //     {
      //       title: '生产管理',
      //       icon: 'gears',
      //       can: true,
      //       children: [
      //         { title: '进度查询', icon: 'pencil-square-o', can: true, name: 'Records' },
      //       ],
      //     },
      //   ];
      // }
      const menu = result.filter((item) => item.can);
      return menu;
    },
  },
  methods: {
    // 最小显示和正常显示切换
    changeToggle() {
      this.$root.$set(this.$root.$data, 'isToggle', !this.$root.isToggle);
    },
    // 递归遍历菜单，设置权限
    menuProcess(rootList, item, parent, subName = 'children', checked = false) {
      let list = rootList;
      if (parent) {
        item.parent = parent;
        list = parent[subName];
      }
      let next = null;
      let nextChecked = false;
      let nextParent = parent;
      if (checked) {
        this.overFun(item);
        const index = list.indexOf(item);
        if (index < list.length - 1) {
          next = list[index + 1];
        }
      } else {
        this.everyFun(item);
        if (item[subName] && item[subName].length > 0) {
          next = item[subName][0];
          nextParent = item;
        } else {
          const index = list.indexOf(item);
          if (index < list.length - 1) {
            next = list[index + 1];
          }
        }
      }
      if (!next) {
        if (parent) {
          next = parent;
          nextChecked = true;
          nextParent = parent.parent;
        } else {
          return;
        }
      }
      this.menuProcess(rootList, next, nextParent, subName, nextChecked);
    },
    // 遍历时调用的函数
    everyFun(item) {
      if (item.name) {
        if (!item.perms) {
          item.can = true;
          if (item.escPerms) {
            let escape = item.escPerms;
            if (typeof escape === 'object') {
              escape = escape.join('|');
            }
            item.can = !this.$utils.can(escape);
          }
        } else {
          let allows = item.perms;
          if (typeof allows === 'object') {
            allows = allows.join('|');
          }
          if (allows) {
            item.can = this.$utils.can(allows);
          } else {
            item.can = true; // 默认有
          }
          // 暂时
          // item.can = false;
        }
      }
    },
    // 遍历完子项后调用的函数（如果子项最少一个访问权限就可以调用）
    overFun(item) {
      let isCan = false;
      for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].can) {
          isCan = true;
          break;
        }
      }
      item.can = isCan;
    },
  },
};
</script>
